import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { appRoutesObj } from './app.paths';
import ComponentLoader from './components/component-loader/component-loader';
import { lazyWithRetry } from './core/utils/lazy-loading.utils';
import AppRedirectPage from './pages/app-redirect-page/app-redirect-page';
import TemporaryMrsoolRedirectPage from './pages/temporary-mrsool-page/temporary-mrsool-redirect-page';
import MaziahPage from './pages/maziah-page/maziah-page';
import MaziahVerificationPage from './pages/maziah-page/maziah-verification-page';
import KKUPage from './pages/kku-page/kku-page';
import KKUVerificationPage from './pages/kku-page/kku-verification-page';

const HomePage = lazyWithRetry(() => import('./pages/home-page/home-page'));
const BanksPage = lazyWithRetry(() => import('./pages/banks-page/banks-page'));
const MoneyReportPage = lazyWithRetry(
  () => import('./pages/money-report-page/money-report-page')
);
const InvestPage = lazyWithRetry(
  () => import('./pages/invest-page/invest-page')
);
const PrivacyPolicyPage = lazyWithRetry(
  () => import('./pages/privacy-policy-page/privacy-policy-page')
);
const TermsPage = lazyWithRetry(() => import('./pages/terms-page/terms-page'));
const BusinessPage = lazyWithRetry(
  () => import('./pages/business-page/business-page')
);
const EduPage = lazyWithRetry(() => import('./pages/edu-page/edu-page'));
const BlogsPage = lazyWithRetry(() => import('./pages/blogs-page/blogs-page'));
const BlogDetailsPage = lazyWithRetry(
  () => import('./pages/blog-details-page/blog-details-page')
);

const withSuspense = (WrappedComponent: React.ComponentType) => {
  return (
    <Suspense fallback={<ComponentLoader />}>
      <WrappedComponent />
    </Suspense>
  );
};

export const withSuspenseComponents = (element: JSX.Element) => {
  const newComponent = () => withSuspense(element.props.component);

  return { ...element, props: { ...element.props, component: newComponent } };
};

interface InfluencerNationalDayReferralPageProps {
  referral_code: string;
}

const InfluencerNationalDayReferralPage: React.FC<
  InfluencerNationalDayReferralPageProps
> = ({ referral_code }) => {
  useEffect(() => {
    // Replace the referral_code in the URL
    const url = `https://drahim.go.link/view/national-day-referral?adj_t=1g6jh6ky&adj_deep_link=drahim%3A%2F%2Fview/national-day-referral/${encodeURIComponent(
      referral_code
    )}&adjust_deeplink_js=1&referral_code=${encodeURIComponent(
      referral_code
    )}&deeplink=drahim://view/national-day-referral`;

    // Programmatically redirect to the deep link
    window.location.href = url;
  }, [referral_code]);

  // Optionally, render a fallback UI while redirecting
  return <div className='text-primary-200 pt-10'>Redirecting...</div>;
};

export function AppRouting() {
  return (
    <Suspense
      fallback={
        <div className='text-primary-200 pt-10'>
          <ComponentLoader />
        </div>
      }
    >
      <Routes>
        <Route
          key='AZ94'
          path='/AZ94'
          element={<InfluencerNationalDayReferralPage referral_code='AZ94' />}
        />
        <Route
          key='AA94'
          path='/AA94'
          element={<InfluencerNationalDayReferralPage referral_code='AA94' />}
        />
        <Route
          key='mrsool'
          path={appRoutesObj.getMrsoolPath()}
          element={withSuspenseComponents(<TemporaryMrsoolRedirectPage />)}
        />
        <Route
          key='app'
          path={appRoutesObj.getAppPath()}
          element={withSuspenseComponents(<AppRedirectPage />)}
        />
        <Route
          key='home'
          path={appRoutesObj.getBaseUrl()}
          element={withSuspenseComponents(<HomePage />)}
        />
        <Route
          key='banks'
          path={appRoutesObj.getBanksPath()}
          element={withSuspenseComponents(<BanksPage />)}
        />
        <Route
          key='money-report'
          path={appRoutesObj.getMoneyReportPath()}
          element={withSuspenseComponents(<MoneyReportPage />)}
        />
        <Route
          key='invest'
          path={appRoutesObj.getInvestPath()}
          element={withSuspenseComponents(<InvestPage />)}
        />
        <Route
          key='privacy'
          path={appRoutesObj.getPrivacyPolicyPath()}
          element={withSuspenseComponents(
            <PrivacyPolicyPage isArabic={true} />
          )}
        />
        <Route
          key='en/privacy'
          path={appRoutesObj.getEnglishPrivacyPolicyPath()}
          element={withSuspenseComponents(
            <PrivacyPolicyPage isArabic={false} />
          )}
        />
        <Route
          key='ar/privacy'
          path={appRoutesObj.getArabicPrivacyPolicyPath()}
          element={withSuspenseComponents(
            <PrivacyPolicyPage isArabic={true} />
          )}
        />
        <Route
          key='terms'
          path={appRoutesObj.getTermsPath()}
          element={withSuspenseComponents(<TermsPage />)}
        />
        <Route
          key='business'
          path={appRoutesObj.getBusinessPath()}
          element={withSuspenseComponents(<BusinessPage />)}
        />
        <Route
          key='edu'
          path={appRoutesObj.getEduPath()}
          element={withSuspenseComponents(<EduPage />)}
        />
        <Route
          key='maziah'
          path={appRoutesObj.getMaziahPath()}
          element={withSuspenseComponents(<MaziahPage />)}
        />
        <Route
          key='maziah/activate'
          path={appRoutesObj.getMaziahActivationPath()}
          element={withSuspenseComponents(<MaziahVerificationPage />)}
        />
        <Route
          key='kku'
          path={appRoutesObj.getKKUPath()}
          element={withSuspenseComponents(<KKUPage />)}
        />
        <Route
          key='kku/activate'
          path={appRoutesObj.getKKUActivationPath()}
          element={withSuspenseComponents(<KKUVerificationPage />)}
        />
        <Route
          key='blogs'
          path={appRoutesObj.getBlogsPath()}
          element={withSuspenseComponents(<BlogsPage />)}
        />
        <Route
          key='blog-details'
          path={appRoutesObj.getBlogDetailsPath()}
          element={withSuspenseComponents(<BlogDetailsPage />)}
        />
        {/* <Route
					key="notDefined"
					path="*"
					element={<Navigate to={appRoutesObj.getBasePath()} />}
				/> */}
      </Routes>
    </Suspense>
  );
}
